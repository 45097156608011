import { PROJECT_LOCKS } from "./constants";

export function useProjectLocks(user, project = null) {
  const { project_locks } = project ?? {};
  const { is_superuser } = user;

  function isLocked(value) {
    if (!PROJECT_LOCKS[value]) {
      throw new Error(`Invalid project lock value`);
    }

    if (is_superuser) {
      return false;
    }

    return !!project_locks?.[value];
  }

  return {
    isLocked,
  };
}
