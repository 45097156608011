import React from "react";
import { FormGroup, CKEditor } from "components/common";

export const ContentField = ({ readOnly }) => {
  return (
    <>
      <FormGroup
        label="Welcome message"
        name="project_content.welcome_message"
        required
      >
        <CKEditor readOnly={readOnly} name="project_content.welcome_message" />
      </FormGroup>
      <hr />
      <FormGroup
        label="Project instructions"
        name="project_content.getting_started"
        required
      >
        <CKEditor name="project_content.getting_started" readOnly={readOnly} />
      </FormGroup>
      <hr />
      <FormGroup label="About" name="project_content.about" required>
        <CKEditor name="project_content.about"  readOnly={readOnly} />
      </FormGroup>
      <hr />
      <FormGroup label="Contact us" name="project_content.contact_us" required>
        <CKEditor name="project_content.contact_us"  readOnly={readOnly} />
      </FormGroup>
    </>
  );
};

export default ContentField;
