import { FormCheck, FormGroup } from "../../components/common";
import { ProjectOwnerSearch } from "./ProjectOwnerSearch";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import React from "react";
import { PROJECT_LOCKS } from "./constants";

export function ProjectAdminTab() {
  const { t } = useTranslation();
  const locks = [
    {
      label: t("Create/edit project"),
      value: PROJECT_LOCKS.project,
    },
    {
      label: t("Content"),
      value: PROJECT_LOCKS.content,
    },
    {
      label: t("Reference intakes"),
      value: PROJECT_LOCKS.reference_intakes,
    },
    {
      label: t("Reminders"),
      value: PROJECT_LOCKS.reminders,
    },
    {
      label: t("Diary options"),
      value: PROJECT_LOCKS.diary_options,
    },
    {
      label: t("Databases"),
      value: PROJECT_LOCKS.databases,
    },
    {
      label: t("Participants"),
      value: PROJECT_LOCKS.participants,
    },
  ];

  return (
    <div>
      <FormGroup
        name="owned_by_user"
        label={t("Owned by user")}
        translateLabel={false}
      >
        <Field
          name="owned_by_user"
          render={({ field, form }) => {
            const { name } = field;
            const { setFieldValue, values } = form;
            const {
              owned_by_user,
              owned_by_user__email,
              owned_by_user__name,
            } = values;

            const value = owned_by_user
              ? {
                  id: owned_by_user,
                  full_name: owned_by_user__name,
                  email: owned_by_user__email,
                }
              : null;

            function onChange(value) {
              setFieldValue(name, value?.id ?? null);
              setFieldValue("owned_by_user__name", value?.full_name ?? "");
              setFieldValue("owned_by_user__email", value?.email ?? "");
            }

            return <ProjectOwnerSearch onChange={onChange} value={value} />;
          }}
        />
      </FormGroup>
      <FormGroup label={t("Locks")} translateLabel={false}>
        {locks.map((lock) => (
          <FormCheck
            key={lock.value}
            label={lock.label}
            input={
              <Field
                name={`project_locks.${lock.value}`}
                type="checkbox"
                className="form-check-input"
              />
            }
          />
        ))}
      </FormGroup>
    </div>
  );
}
