import React, { useState } from "react";
import { FormikProvider, useFormik } from "formik";
import {
  SubmitButton,
  Button,
  Page,
  FormGroup,
  FileField,
} from "components/common";
import { useTable, useModal, useClient } from "hooks";
import { NotificationManager } from "react-notifications";
import { Table, Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "services/formatters";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {Trans, useTranslation} from "react-i18next";
import {DateTimePicker} from "../DatabaseImport";

export const Accepted = ({ project }) => {
  const url = "participants/accepted";
  const {
    TableContainer,
    TableDataFetch,
    data,
    refreshData,
    BooleanIcon,
  } = useTable({
    url,
    ordering: "respondent_id",
    limit: null,
    filters: {
      project,
    },
  });
  const {
    Modal,
    ModalBody,
    isOpen,
    setIsOpen,
    ModalFooter,
    CloseButton,
  } = useModal();
  const client = useClient();
  const deleteForm = useFormik({
    onSubmit: onDeleteSubmit,
    initialValues: {},
  });

  async function onDeleteSubmit({ id }) {
    await client.delete(`${url}/${id}`);
    NotificationManager.success("Invited participant deleted successfully");
    setIsOpen(false);
    refreshData();
  }

  return (
    <Page title="Accepted participants" flat translateTitle>
      {isOpen ? (
        <Modal>
          <FormikProvider value={deleteForm}>
            <ModalBody>
              <p className="mb-2">
                <Trans>Are you sure you want delete this participant?</Trans>
              </p>
              <p>
                <Trans>
                  Please only delete this participant if you do not want them to
                  be in this project as you will not be able to add them again.
                </Trans>
              </p>
            </ModalBody>
            <ModalFooter>
              <CloseButton />
              <SubmitButton>OK</SubmitButton>
            </ModalFooter>
          </FormikProvider>
        </Modal>
      ) : null}
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th>
                <Trans>Name</Trans>
              </th>
              <th>
                <Trans>Email address</Trans>
              </th>
              <th>
                <Trans>Email sent</Trans>
              </th>
              <th>
                <Trans>Respondent ID</Trans>
              </th>
              <th>
                <Trans>Is diary submitted?</Trans>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>
                  <Link to={`../../participants/accepted/${i.id}`}>
                    {i.user__full_name}
                  </Link>
                </td>
                <td>{i.user__email}</td>
                <td />
                <td>{i.respondent_id}</td>
                <td className="text-center">
                  <BooleanIcon test={i.is_diary_submitted} />
                </td>

                <td>
                  <Button
                    color="link"
                    onClick={() => {
                      deleteForm.setFieldValue("id", i.id);
                      setIsOpen(true);
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <TableDataFetch />
    </Page>
  );
};

const ResendEmailButton = ({ url, participant, ...props }) => {
  const form = useFormik({
    initialValues: {},
    onSubmit,
  });
  const {
    Modal,
    isOpen,
    setIsOpen,
    ModalBody,
    ModalFooter,
    CloseButton,
  } = useModal();
  const client = useClient();

  async function onSubmit() {
    await client.post(url).get("data");
    NotificationManager.success(
      `Successfully resent invitation email to ${participant.invitation__full_name}`
    );
    setIsOpen(false);
  }

  return (
    <FormikProvider value={form}>
      {isOpen ? (
        <Modal>
          <ModalBody>
            Are you sure you want to re-send an invitation email to this invited
            participant?
          </ModalBody>
          <ModalFooter>
            <CloseButton />
            <SubmitButton>OK</SubmitButton>
          </ModalFooter>
        </Modal>
      ) : null}
      <Button onClick={() => setIsOpen(true)} {...props}>
        <Trans>Re-send email</Trans>
      </Button>
    </FormikProvider>
  );
};

const Invited = ({ project }) => {
  const url = `projects/${project}`;
  const { t } = useTranslation();
  const [uploadErrors, setUploadErrors] = useState([]);
  const uploadForm = useFormik({
    onSubmit: onUpload,
    initialValues: {
      file: null,
      send_invitations: false,
    },
    isInitialValid: false,
  });
  const { file: fileError } = uploadForm.errors;

  const { TableContainer, TableDataFetch, data, refreshData } = useTable({
    url: "participants/invited",
    ordering: "respondent_id",
    limit: null,
    filters: {
      project,
    },
  });
  const {
    Modal,
    ModalBody,
    isOpen,
    setIsOpen,
    ModalFooter,
    CloseButton,
  } = useModal();
  const {
    Modal: SendNewModal,
    isOpen: isSendNewOpen,
    setIsOpen: setIsSendNewOpen,
    CloseButton: SendNewCloseButton,
  } = useModal();
  const {
    Modal: SendAllModal,
    isOpen: isSendAllOpen,
    setIsOpen: setIsSendAllOpen,
    CloseButton: SendAllCloseButton,
  } = useModal();
  const deleteForm = useFormik({
    onSubmit: onDeleteSubmit,
    initialValues: {},
  });
  const sendAllForm = useFormik({
    onSubmit: onSendAll,
    initialValues: {},
  });
  const sendNewForm = useFormik({
    onSubmit: onSendNew,
    initialValues: {},
  });

  async function onDeleteSubmit({ id }) {
    await client.delete(`participants/invited/${id}`);
    NotificationManager.success("Invited participant deleted successfully");
    setIsOpen(false);
    refreshData();
  }
  const client = useClient();

  async function onSendAll() {
    const { count } = await client
      .post(`${url}/resend_invitation_emails`)
      .get("data");
    NotificationManager.success(
      `Successfully sent ${count} invitation email(s)`
    );
    setIsSendAllOpen(false);
    await refreshData();
  }

  async function onSendNew() {
    const { count } = await client
      .post(`${url}/send_unsent_invitation_emails`)
      .get("data");
    NotificationManager.success(
      `Successfully sent ${count} invitation email(s)`
    );
    setIsSendNewOpen(false);
    await refreshData();
  }

  async function onUpload(x) {
    setUploadErrors([]);

    try {
      const { count } = await client
        .post(`${url}/upload_participants`, x)
        .get("data");
      NotificationManager.success(`Successfully uploaded ${count} participant`);
      uploadForm.resetForm();
      await refreshData();
    } catch (ex) {
      const data = ex?.response?.data;
      const code = data?.code;
      const detail = data?.detail;

      if (code === "UploadInvalid") {
        setUploadErrors(detail);
      } else {
        throw ex;
      }
    }
  }

  return (
    <div>
      {isSendNewOpen ? (
        <SendNewModal>
          <FormikProvider value={sendNewForm}>
            <ModalBody>
              Are you sure you want to send invitation emails to newly-added
              participants?
            </ModalBody>
            <ModalFooter>
              <SendNewCloseButton />
              <SubmitButton>OK</SubmitButton>
            </ModalFooter>
          </FormikProvider>
        </SendNewModal>
      ) : null}
      {isSendAllOpen ? (
        <SendAllModal>
          <FormikProvider value={sendAllForm}>
            <ModalBody>
              Are you sure you want to re-send invitation emails to all
              participants?
            </ModalBody>
            <ModalFooter>
              <SendAllCloseButton />
              <SubmitButton>OK</SubmitButton>
            </ModalFooter>
          </FormikProvider>
        </SendAllModal>
      ) : null}
      <FormikProvider value={uploadForm}>
        <Row form className="mb-1">
          <Col xl="auto" className="mb-2 xl:mb-0">
            <FormGroup label="CSV/Excel upload" className="mb-0">
              <FileField
                validate={(v) => {
                  if (!v) return "required";
                  if (v.size === 0) return "invalid_size";
                  if (!/^.*\.(csv|xlsx)$/i.test(v.name)) return "invalid_type";
                  return false;
                }}
                name="file"
              />
            </FormGroup>
          </Col>
          <Col xl="auto" className="self-end mb-1 xl:mb-0">
            <SubmitButton
              size="sm"
              disabledWhenInvalid
              color="secondary"
              onClick={async () => {
                uploadForm.setFieldValue("send_invitations", false);
                await uploadForm.submitForm();
              }}
            >
              <Trans>Upload (do not send invitation emails)</Trans>
            </SubmitButton>
          </Col>
          <Col xl="auto" className="self-end">
            <SubmitButton
              size="sm"
              disabledWhenInvalid
              color="secondary"
              onClick={async () => {
                uploadForm.setFieldValue("send_invitations", true);
                await uploadForm.submitForm();
              }}
            >
              <Trans>Upload and send invitation emails</Trans>
            </SubmitButton>
          </Col>
        </Row>
        {fileError === "invalid_size" ? (
          <Alert color="danger">Uploaded file is empty</Alert>
        ) : null}
        {fileError === "invalid_type" ? (
          <Alert color="danger">
            Uploaded file does not have the expected file extension (*.csv, *.xlsx)
          </Alert>
        ) : null}
        {uploadErrors.length ? (
          <Alert color="danger">
            <ul>
              {uploadErrors.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <p>
          <Trans>
            You can download a template for participants in either CSV or Excel format:
          </Trans>
            <ul>
            <li>
              <a download href="/static/documents/participant-template.csv">
                <Trans>Click here to download the CSV template</Trans>
              </a>
            </li>
            <li>
              <a download href="/static/documents/participant-template.xlsx">
                <Trans>Click here to download the Excel template</Trans>
              </a>
            </li>
          </ul>
        </p>
      </FormikProvider>
      <hr />
      <Page title="Invited participants" flat translateTitle>
        <Row form className="-mt-2 mb-4">
          <Col lg="auto" className="self-end mb-1 lg:mb-0">
            <SubmitButton
              size="sm"
              color="secondary"
              onClick={async () => {
                setIsSendNewOpen(true);
              }}
            >
              <Trans>Send invitation emails to newly added participants</Trans>
            </SubmitButton>
          </Col>
          <Col lg="auto" className="self-end">
            <SubmitButton
              size="sm"
              color="secondary"
              onClick={async () => {
                setIsSendAllOpen(true);
              }}
            >
              <Trans>Re-send invitation emails to all participants</Trans>
            </SubmitButton>
          </Col>
        </Row>
        {isOpen ? (
          <Modal>
            <FormikProvider value={deleteForm}>
              <ModalBody>
                Are you sure you want delete this invited participant?
              </ModalBody>
              <ModalFooter>
                <CloseButton />
                <SubmitButton>OK</SubmitButton>
              </ModalFooter>
            </FormikProvider>
          </Modal>
        ) : null}
        <TableContainer>
          <Table size="sm" hover striped>
            <thead>
              <tr>
                <th>
                  <Trans>Name</Trans>
                </th>
                <th>
                  <Trans>Email address</Trans>
                </th>
                <th>
                  <Trans>Email sent</Trans>
                </th>
                <th>
                  <Trans>Respondent ID</Trans>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data.results.map((i) => (
                <tr key={i.id}>
                  <td>
                    <Link to={`../../participants/invited/${i.id}`}>
                      {i.invitation__full_name}
                    </Link>
                  </td>
                  <td>{i.invitation__email}</td>
                  <td>
                    {i.date_time_invite_invitation_email_sent
                      ? formatDateTime(i.date_time_invite_invitation_email_sent)
                      : "Not sent"}
                  </td>
                  <td>{i.respondent_id}</td>
                  <td>
                    <ResendEmailButton
                      participant={i}
                      url={`participants/invited/${i.id}/resend_invitation_email`}
                      size="sm"
                      color="secondary"
                      className="mr-2"
                    />
                    <Button
                      color="link"
                      onClick={() => {
                        deleteForm.setFieldValue("id", i.id);
                        setIsOpen(true);
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red-500"
                      />
                    </Button>
                  </td>
                </tr>
              ))}
              {!data.results.length ? (
                <tr>
                  <td colSpan={999} className="text-center">
                    <Trans>
                      There are no invited participants for this project
                    </Trans>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </TableContainer>
        <TableDataFetch />
      </Page>
      <Page title={t("Schedule unsent invitation emails")} flat>
        <p className="mb-2">
          <Trans>
            Set a date and time below to schedule unsent invitation emails to be sent to the participants.
          </Trans>
        </p>
        <FormGroup label={t("Send unsent invitation emails on")} name="invites_send_on" helpText={
          t('You must Save after setting this date/time')
        }>
          <DateTimePicker
            name="invites_send_on"
            className="form-control"
          />
      </FormGroup>
      </Page>
    </div>
  );
};

const Participants = ({ project }) => {
  return (
    <>
      <Accepted project={project} />
      <hr />
      <Invited project={project} />
    </>
  );
};

export default Participants;
